import he from "he";
import { callPaymentAPI } from "../../../../../_common/Api";
import { formatOrderType, getDeviceTypeId } from "../../../../../_common/helpers";
import { formatCartForApi } from "../../../../../_common/CartHelpers";

export const placeAPIOrder = async (
  skin,
  activeStore,
  orderType,
  customerInfo,
  rewards,
  promoCode,
  cartItems,
  giftCardNumberList,
  timestamp,
  deliveryAddress,
  orderTypeInstruction,
  tableNumber,
  tipAmount,
  language,
  generalLabels,
  isStadiumSchema = false,
  isLoggedIn = false,
  loginToken = ""
) => {
  const pickUpTimestamp = timestamp || "";

  let order_address = activeStore.address;
  let order_postal = activeStore.postal;
  let order_city = activeStore.city;
  let delivery_province = null
  if (orderType === "delivery") {
    if (isStadiumSchema) {
      order_postal = deliveryAddress;
    } else {
      const isSecondAddress = deliveryAddress["second-address"];
      order_address =
        deliveryAddress["first-address"] +
        (isSecondAddress ? ", #" + deliveryAddress["second-address"] : "");
      order_postal = deliveryAddress.postal;
      order_city = deliveryAddress.city;
      delivery_province = deliveryAddress.province;
    }
  }

  const placeOrderData = await callPaymentAPI(skin, "dc_cws_vxl_online_order", {
    params: [
      skin,
      "consumer",
      language,
      activeStore.storeId,
      formatOrderType(orderType),
      pickUpTimestamp, // Order Pickup Timestamp (optional)
      {
        customer_id: customerInfo.id || "",
        order_email: customerInfo.email || "",
        order_fname: customerInfo.firstName || "",
        order_lname: customerInfo.lastName || "",
        order_phone: customerInfo.phone || "",
        order_mobile: customerInfo.mobile || "",
        order_address,
        order_postal,
        order_city: order_city,
        order_prov: delivery_province ? delivery_province : activeStore.province,
        order_country: activeStore.country,
        discount_for_the_whole_order: promoCode,
        redeem_rewards: rewards,
        customer_token: isLoggedIn ? loginToken : ""
      }, // CustomerInfo
      formatCartForApi(cartItems), // ItemsList
      orderType === "delivery" ? orderTypeInstruction : "None", // Delivery Instructions
      orderType === "dinein" ? tableNumber : "", // Table Number (optional)
      customerInfo.cpfNumber, // government_id
      giftCardNumberList, // gc_number_list
      "", // save_credit_card
      "", // tm_session_id
      "", // scene_cardnum
      "CREDITCARD", // payment_type
      "", // cc_id
      "", // cc_type
      "", // cc_number
      "", // cc_name
      "", // cc_security
      "", // cc_issue
      "", // cc_expiry_month
      "", // cc_expiry_year
      "", // cc_start_month
      "", // cc_start_year
      "", // cc_address1
      "", // cc_address2
      "", // cc_city
      "", // cc_province
      "", // cc_country
      "", // cc_postal
      customerInfo.email, // cc_email
      customerInfo.phone, // cc_phone
      "", // pa_res
      "", // return_url
      "", // cancel_url
      "", // success_url
      "", // error_url
      "", // fail_url
      "", // unique_pay_id
      "", // wechat_oauth_id
      "", // url_3ds_success
      "", // url_3ds_failure
      orderType === "pickup" || orderType === "dinein" ? orderTypeInstruction : "None", // //Special Instruction (pickup)
      tipAmount, //tip
      getDeviceTypeId(orderType), // device type id
    ],
    id: "dc_cws_vxl_online_order",
  });

  if (placeOrderData.result.I0) {
    /* successfully placed an order */
    return {
      isPlaced: true,
      orderId: placeOrderData.result.I0,
      pointsIssued: placeOrderData.result.I10,
    };
  } else {
    /* failed to place an order */
    return {
      isPlaced: false,
      error: `${generalLabels["error-code"]}: ${placeOrderData.error.code}. ${he.decode(
        placeOrderData.result.message
      )}`,
    };
  }
};
